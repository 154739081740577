<template>
  <div class="container px-0 h-100">
    <SettingsInsurerView />
  </div>
</template>

<script>
import SettingsInsurerView from "../../../content/settings/insurer/View";
import { INSURER_LIST } from "@/core/services/store/insurer.module";

export default {
  name: "SettingsInsurerPageView",
  components: { SettingsInsurerView },
  mounted() {
    this.$store.dispatch(INSURER_LIST);
  }
};
</script>
