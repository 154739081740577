<template>
  <div>
    <h3 class="text-secondary content-heading">Ustawienia > Insurer</h3>
    <div class="row">
      <div class="col-12">
        <div class="wide-box">
          <div class="card border-radius-1">
            <div class="card-header d-flex align-items-end">
              <div class="card-title flex-grow-1">
                <div class="card-toolbar">
                  <a v-b-modal.modal-insurer-add class="">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Code/Plus.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <ModalInsurerAdd />
                </div>
              </div>
            </div>
            <div class="card-body">
              <b-table
                :items="insurersList.items"
                :fields="insurersList.fields"
                responsive="sm"
                show-empty
                small
              >
                <template #cell(actions)="row">
                  <b-button size="sm" @click="row.toggleDetails">
                    {{ row.detailsShowing ? "Hide" : "Show" }} Details
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-1"
                    @click="info(row.item, row.index, $event.target)"
                  >
                    Info modal
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-1"
                    @click="edit(row.item, row.index, $event.target)"
                  >
                    Edit
                  </b-button>
                </template>

                <template #row-details="row">
                  <b-card>
                    <ul>
                      <li v-for="(value, key) in row.item" :key="key">
                        {{ key }}: {{ value }}
                      </li>
                    </ul>
                  </b-card>
                </template>
              </b-table>

              <!-- Info modal -->
              <b-modal
                :id="infoModal.id"
                :title="infoModal.title"
                ok-only
                @hide="resetInfoModal"
              >
                <pre>{{ infoModal.content }}</pre>
              </b-modal>

              <ModalInsurerEdit :id="editModal.id" :uuid="editModal.uuid" />

              <!--              <ClassicTable-->
              <!--                :items="insurersList"-->
              <!--                :no-headers="false"-->
              <!--                title-style="mb-0"-->
              <!--              />-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalInsurerAdd from "@/view/content/components/modal/ModalInsurerAdd";
import ModalInsurerEdit from "@/view/content/components/modal/ModalInsurerEdit";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "SettingsInsurerView",
  components: { ModalInsurerAdd, ModalInsurerEdit },
  data() {
    return {
      selected: [],
      insurersList: {
        fields: [
          {
            key: "uuid",
            label: "UUID"
          },
          {
            key: "name",
            label: "Nazwa"
          },
          {
            key: "city",
            label: "Miasto"
          },
          {
            key: "actions",
            label: "Akcje"
          }
        ],
        items: []
      },
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      },
      editModal: {
        id: "edit-modal",
        uuid: ""
      }
    };
  },
  computed: {
    ...mapGetters(["insurers"])
  },
  watch: {
    insurers(newVal) {
      this.parseDataForTable(newVal);
    }
  },
  mounted() {
    /**
     * TODO This need to be reworked as when refreshing, data is not available yet so he table is empty
     */
    this.parseDataForTable(this.insurers);
  },
  methods: {
    edit(item, index, button) {
      this.editModal.uuid = item.uuid;
      this.$root.$emit("bv::show::modal", this.editModal.id, button);
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    resetEditModal() {
      this.infoModal.uuid = "";
    },
    parseDataForTable(insurers) {
      this.insurersList.items = [];
      _.forEach(insurers, insurer => {
        this.insurersList.items.push({
          uuid: insurer.uuid,
          name: insurer.name,
          city: insurer.city
        });
      });
    }
  }
};
</script>
