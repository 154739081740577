<template>
  <div>
    <!-- The modal -->
    <b-modal
      id="modal-insurer-add"
      ref="insurer"
      title="Dodaj Insurera"
      ok-title="Dodaj"
      cancel-title="Anuluj"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @show="resetForm"
      @cancel="resetForm"
      @ok="handleModalOk"
    >
      <template #modal-header="{ title, close }">
        <!-- Emulate built in modal header close button action -->
        <h5>Dodaj Insurera</h5>
        <b-button size="sm" variant="outline-primary" @click="close()">
          Zamknij
        </b-button>
        <b-button size="sm" variant="outline-warning" @click="mock()">
          Nanieś
        </b-button>
      </template>

      <b-form novalidate>
        <b-form-group
          label="name"
          label-for="name"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.name"></b-form-input>
        </b-form-group>

        <b-form-group
          label="full_name"
          label-for="full_name"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.full_name"></b-form-input>
        </b-form-group>

        <b-form-group
          label="street_name"
          label-for="street_name"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.street_name"></b-form-input>
        </b-form-group>

        <b-form-group
          label="street_prefix"
          label-for="street_prefix"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.street_prefix"></b-form-input>
        </b-form-group>

        <b-form-group
          label="building_number"
          label-for="building_number"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.building_number"></b-form-input>
        </b-form-group>

        <b-form-group
          label="apartment_number"
          label-for="apartment_number"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.apartment_number"></b-form-input>
        </b-form-group>

        <b-form-group
          label="postcode"
          label-for="postcode"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.postcode"></b-form-input>
        </b-form-group>

        <b-form-group
          label="city"
          label-for="city"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.city"></b-form-input>
        </b-form-group>

        <b-form-group
          label="province"
          label-for="province"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.province"></b-form-input>
        </b-form-group>

        <b-form-group
          label="country"
          label-for="country"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.country"></b-form-input>
        </b-form-group>

      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { INSURER_ADD } from "@/core/services/store/insurer.module";
import { baseURL } from "@/core/services/api.service.js";

export default {
  name: "ModalInsurerAdd",
  data() {
    return {
      form: {
        formState: null,
        name: "",
        full_name: "",
        street_name: "",
        street_prefix: "",
        building_number: "",
        apartment_number: "",
        postcode: "",
        city: "",
        province: "",
        country: ""
      }
    };
  },
  computed: {
    getBaseURL() {
      return baseURL;
    }
  },
  mounted() {},
  methods: {
    mock() {
      this.form.name = "Fundacja";
      this.form.full_name = "Fundacja Sp. z o.o.";
      this.form.street_name = "Mleczna";
      this.form.street_prefix = "droga";
      this.form.building_number = "21";
      this.form.apartment_number = "";
      this.form.postcode = "00-001";
      this.form.city = "Warszawa";
      this.form.province = "mazowieckie";
      this.form.country = "Trantor";
    },
    resetForm() {
      this.form.name = "";
      this.form.full_name = "";
      this.form.street_name = "";
      this.form.street_prefix = "";
      this.form.building_number = "";
      this.form.apartment_number = "";
      this.form.postcode = "";
      this.form.city = "";
      this.form.province = "";
      this.form.country = "";
    },
    handleModalOk(event) {
      event.preventDefault();
      this.handleSubmit();
    },
    validateForm() {
      this.form.formState = true;
    },
    handleSubmit() {
      this.validateForm();
      if (this.form.formState) {
        let formToSend = this.form;
        this.$store.dispatch(INSURER_ADD, formToSend);
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide("modal-insurer-add");
        });
      }
    }
  }
};
</script>
